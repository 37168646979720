import React from 'react';

interface H3Props {
  className?: string;
}
const H3: React.FC<H3Props> = ({ children, className }) => (
  <h3 className={`${className} font-sans text-2xl text-des-green mb-4 `}>
    {children}
  </h3>
);

export default H3;
