import React from 'react';
import Modal from 'react-modal';
import StandardButton from './Buttons/StandardButton';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: string;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  children,
  isOpen,
  onConfirm,
  onCancel,
  confirmText,
}) => (
  <>
    <Modal style={customStyles} isOpen={isOpen} onRequestClose={onCancel}>
      <div className="max-w-xl">
        {children}
        <div className="flex my-6 justify-between">
          <div>
            <StandardButton onClick={onCancel} secondary>
              Annuler
            </StandardButton>
          </div>
          <div>
            <StandardButton onClick={onConfirm}>{confirmText}</StandardButton>
          </div>
        </div>
      </div>
    </Modal>
  </>
);

export default ConfirmationModal;
