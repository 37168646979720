import React from 'react';
import Headbar from './Headbar';
import SideMenu from './SideMenu';

const Layout: React.FC = ({ children }) => (
  <>
    <Headbar />
    <div className="w-screen min-h-screen flex  bg-des-bg-gray">
      <div className="w-1/6">
        <SideMenu />
      </div>
      <div className="w-5/6 m-6">{children}</div>
    </div>
  </>
);

export default Layout;
