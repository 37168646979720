import React, { useMemo } from 'react';

import H2 from '../components/ui/H2';
import { FileTypes } from '../models';
import UploadPdfFile from '../components/Files/UploadPdfFile';
import UploadSignatureFile from '../components/Files/UploadSignatureFile';
import H3 from '../components/ui/H3';
import useFiles from '../hooks/useFiles';

const Files: React.FC = () => {
  const { files, actions } = useFiles();

  const faqFile = useMemo(
    () => files.find(({ type }) => type === FileTypes.Faq) || {},
    [files]
  );
  const goodPracticeFile = useMemo(
    () => files.find(({ type }) => type === FileTypes.GoodPractices) || {},
    [files]
  );

  const signatureFiles = useMemo(
    () => files.filter(({ type }) => type === FileTypes.Signature),
    [files]
  );

  return (
    <div>
      <H2> Gestion des fichiers</H2>
      <H3> FAQ et Bonnes pratiques </H3>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
        <UploadPdfFile file={faqFile} type={FileTypes.Faq} actions={actions} />
        <UploadPdfFile
          file={goodPracticeFile}
          type={FileTypes.GoodPractices}
          actions={actions}
        />
      </div>
      <H3> Signatures </H3>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
        {signatureFiles.map((signatureFile) => (
          <UploadSignatureFile
            key={signatureFile._id}
            file={signatureFile}
            actions={actions}
          />
        ))}
        <UploadSignatureFile file={{}} actions={actions} />
      </div>
    </div>
  );
};

export default Files;
