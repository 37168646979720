import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useGetFetch from '../hooks/useGetFetch';
import { CardDTO, SubThemeModel } from '../models';
import EditCardForm from '../components/Cards/EditCardForm';
import H3 from '../components/ui/H3';
import { FetchingStatus } from '../utils';
import useCard from '../hooks/useCard';
import Previous from '../components/ui/Previous';

const EditCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { card, status, updateCard, deleteCard, errorMessage } = useCard(id);

  const [subThemes, subThemeStatus] = useGetFetch<SubThemeModel[]>(
    '/subcategories/'
  );

  const dataReady =
    status === FetchingStatus.Fetched &&
    subThemeStatus === FetchingStatus.Fetched;

  const onSave = useCallback((newCard: CardDTO) => updateCard(newCard, !id), [
    id,
    updateCard,
  ]);

  return (
    <div>
      <Previous to="/cards" />
      <H3>{card && id ? 'Édition de la carte :' : 'Ajout de la carte : '}</H3>

      {status === FetchingStatus.Fetching && <div>LOADING</div>}
      {errorMessage && <div>ERREUR : {errorMessage}</div>}

      {dataReady && card && (
        <EditCardForm
          card={card}
          subThemes={subThemes as SubThemeModel[]}
          onSave={onSave}
          onDelete={deleteCard}
        />
      )}
    </div>
  );
};

export default EditCard;
