import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ThemeData, ThemeDTO } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import SubmitButton from '../ui/Buttons/SubmitButton';
import FormElementWrapper from '../ui/FormElementWrapper';
import Input from '../ui/Input';
import Label from '../ui/Label';

interface ThemeFormProps {
  theme: ThemeData;
  onPrimaryClick: (newData: any) => void;
  onSecondaryClick: () => void;
}

const ThemeForm: React.FC<ThemeFormProps> = ({
  theme,
  onSecondaryClick,
  onPrimaryClick,
}) => {
  const { name_FR, name_EN, restricted, members } = theme;

  const { register, handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      name_FR,
      name_EN,
      restricted,
      members,
    },
  });

  const onSubmit = useCallback(
    (newData: ThemeDTO) => {
      onPrimaryClick(newData);
    },
    [onPrimaryClick]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex ">
          <div>
            <Input
              name="name_FR"
              placeholder="nom français"
              register={register({ required: true })}
            />
          </div>
          <div className="mx-4">
            <Input
              name="name_EN"
              placeholder="nom anglais"
              register={register({ required: true })}
            />
          </div>
          <div>
            <StandardButton onClick={onSecondaryClick} secondary>
              Annuler
            </StandardButton>
          </div>
          <div className="mx-4">
            <SubmitButton>Enregistrer</SubmitButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default ThemeForm;
