import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { SubThemeModel, SubThemeDTO, ThemeModel, ThemeDTO } from '../models';
import {
  deleteData,
  FetchingStatus,
  fetchWithToken,
  postJsonData,
  putData,
} from '../utils';

export interface UseThemesActions {
  updateSubTheme: (newData: SubThemeDTO, id?: string) => void;
  deleteSubTheme: (id: string) => void;
  updateTheme: (newData: ThemeDTO, id?: string) => void;
  deleteTheme: (id: string) => void;
}
interface UseThemes {
  themes: ThemeModel[];
  subThemes: SubThemeModel[];
  actions: UseThemesActions;
}
function useTheme(): UseThemes {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Fetching);
  const [openSnackbar] = useSnackbar();

  const [themes, setThemes] = useState<ThemeModel[]>([]);
  const [subThemes, setSubThemes] = useState<SubThemeModel[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const catResponse = await fetchWithToken(`/categories/all`);
        const fetchedThemes: ThemeModel[] = await catResponse.json();
        setThemes(fetchedThemes);
        const subCatResponse = await fetchWithToken(`/subcategories`);
        const fetchedSubThemes: SubThemeModel[] = await subCatResponse.json();
        setSubThemes(fetchedSubThemes);

        setStatus(FetchingStatus.Fetched);
      } catch (error) {
        openSnackbar('Une erreur a empêché la récupération de la carte');
      }
      setStatus(FetchingStatus.Fetched);
    };
    getData();
  }, [setThemes, setSubThemes]);

  const updateSubTheme = useCallback(
    async (newData: SubThemeDTO, id?: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        let updatedSubTheme: SubThemeModel;
        if (!id) {
          updatedSubTheme = await postJsonData<SubThemeModel>(
            `/subcategories/`,
            newData
          );
        } else {
          updatedSubTheme = await putData<SubThemeModel>(
            `/subcategories/${id}`,
            newData
          );
        }

        openSnackbar(`Le sous-theme a bien été sauvegardée`);
        setSubThemes((previous) => {
          if (!id) {
            return previous.concat(updatedSubTheme);
          }
          return previous.map((currentSubTheme) => {
            if (currentSubTheme._id === id) {
              return updatedSubTheme;
            }
            return currentSubTheme;
          });
        });
      } catch (error) {
        openSnackbar('Une erreur a empêché la sauvegarde du sous-theme');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const updateTheme = useCallback(
    async (newData: ThemeDTO, id?: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        let updatedTheme: ThemeModel;
        if (!id) {
          updatedTheme = await postJsonData<ThemeModel>(
            `/categories/`,
            newData
          );
        } else {
          updatedTheme = await putData<ThemeModel>(
            `/categories/${id}`,
            newData
          );
        }

        openSnackbar(`Le thème a bien été sauvegardée`);
        setThemes((previous) => {
          if (!id) {
            return previous.concat(updatedTheme);
          }
          return previous.map((currentTheme) => {
            if (currentTheme._id === id) {
              return updatedTheme;
            }
            return currentTheme;
          });
        });
      } catch (error) {
        openSnackbar('Une erreur a empêché la sauvegarde du sous-thème');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const deleteSubTheme = useCallback(
    async (id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        await deleteData(`/subcategories/${id}`);
        openSnackbar(`Le sous-theme a bien été effacé`);
        setSubThemes((previous) => previous.filter(({ _id }) => _id !== id));
      } catch (error) {
        openSnackbar(`Une erreur a empêché d'effacer le sous-theme`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar, setSubThemes]
  );

  const deleteTheme = useCallback(
    async (id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        await deleteData(`/categories/${id}`);
        openSnackbar(`Le theme a bien été effacé`);
        setThemes((previous) => previous.filter(({ _id }) => _id !== id));
      } catch (error) {
        openSnackbar(`Une erreur a empêché d'effacer le theme`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar, setThemes]
  );

  return {
    themes,
    subThemes,
    actions: {
      updateSubTheme,
      deleteSubTheme,
      updateTheme,
      deleteTheme,
    },
  };
}

export default useTheme;
