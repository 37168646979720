import React, { useMemo } from 'react';

interface SelectValues {
  value: string;
  friendlyName: string;
}

interface SelectProps {
  name: string;
  defaultValue?: string;
  register: any;
  selectValues: SelectValues[];
}
const Select: React.FC<SelectProps> = ({
  name,
  defaultValue,
  register,
  selectValues,
}) => {
  const defaultSelectedValue = useMemo(
    () => defaultValue || selectValues[0].value,
    [selectValues, defaultValue]
  );

  return (
    <select
      defaultValue={defaultSelectedValue}
      ref={register}
      name={name}
      className="p-2 border border-gray-200"
    >
      {selectValues.map(({ value, friendlyName }) => (
        <option key={value} value={value}>
          {friendlyName}
        </option>
      ))}
    </select>
  );
};

Select.defaultProps = {
  defaultValue: '',
};

export default Select;
