import React, { useCallback, useState } from 'react';
import { UseUsersActions } from '../../hooks/useUsers';
import { UserModel } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import ConfirmationModal from '../ui/ConfirmationModal';
import H3 from '../ui/H3';
import Label from '../ui/Label';
import UserForm from './UserForm';

interface SubCategoryProps {
  user: UserModel;
  actions: UseUsersActions;
}
export const User: React.FC<SubCategoryProps> = ({ user, actions }) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onSave = useCallback(
    (newData) => {
      setEditMode(false);
      actions.updateUser(newData, user._id);
    },
    [actions, setEditMode, user._id]
  );
  const onDelete = useCallback(() => {
    actions.deleteUser(user._id);
  }, [actions, user]);

  return (
    <div className="bg-white my-8 p-4 shadow-md">
      {editMode ? (
        <UserForm
          user={user}
          onPrimaryClick={onSave}
          onSecondaryClick={() => setEditMode(false)}
        />
      ) : (
        <>
          <UserView
            user={user}
            onPrimaryClick={() => setEditMode(true)}
            onSecondaryClick={() => setDeleteConfirmOpen(true)}
          />
          <ConfirmationModal
            onConfirm={onDelete}
            onCancel={() => setDeleteConfirmOpen(false)}
            isOpen={deleteConfirmOpen}
            confirmText="Supprimer l'utilisateur"
          >
            <div> Voulez-vous vraiment supprimer cet utilisateur ? </div>
          </ConfirmationModal>
        </>
      )}
    </div>
  );
};

interface UserViewProps {
  user: UserModel;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
}

const UserView: React.FC<UserViewProps> = ({
  user,
  onSecondaryClick,
  onPrimaryClick,
}) => (
  <>
    <div>
      <H3 className="">{user.name}</H3>
      <Label>{user.email}</Label>
      <div className="flex my-4 justify-between">
        <div className="">
          <StandardButton secondary onClick={onSecondaryClick}>
            Supprimer
          </StandardButton>
        </div>
        <div className="">
          <StandardButton onClick={onPrimaryClick}>Éditer</StandardButton>
        </div>
      </div>
    </div>
  </>
);

export default User;
