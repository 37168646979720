import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useHistory } from 'react-router-dom';
import { CardData, CardModel, CardDTO } from '../models';
import {
  FetchingStatus,
  putData,
  deleteData,
  postJsonData,
  fetchWithToken,
} from '../utils';

interface UseCard {
  card: CardData | CardModel | undefined;
  status: string;
  updateCard: (newData: CardDTO, create: boolean) => void;
  deleteCard: () => void;
  errorMessage: string;
}
function useCard(id?: string): UseCard {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Idle);
  const [card, setCard] = useState<CardData | CardModel>();
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar] = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setErrorMessage('');
        setStatus(FetchingStatus.Fetching);
        try {
          const response = await fetchWithToken(`/visual/${id}`);
          const newData: CardModel = await response.json();

          setCard(newData);

          setStatus(FetchingStatus.Fetched);
        } catch (error) {
          setErrorMessage('Impossible de récupérer la carte');
          openSnackbar('Une erreur a empêché la récupération de la carte');
        }
        setStatus(FetchingStatus.Fetched);
      };

      fetchData();
    } else {
      const defaultCard: CardData = {
        url: '',
        thumbnail: '',
        parent: '',
        name_FR: '',
        name_EN: '',
        lang: '',
        restricted: false,
        members: [],
      };
      setCard(defaultCard);
      setStatus(FetchingStatus.Fetched);
    }
  }, [id, setErrorMessage, setStatus]);

  const updateCard = useCallback(
    async (newData: CardDTO, create = false) => {
      setErrorMessage('');
      setStatus(FetchingStatus.Fetching);
      try {
        let updatedCard;
        if (create) {
          updatedCard = await postJsonData<CardModel>(`/visual/`, newData);
        } else {
          updatedCard = await putData<CardModel>(`/visual/${id}`, newData);
        }
        openSnackbar(`La carte a bien été sauvegardée`);
        setCard(updatedCard);
      } catch (error) {
        openSnackbar('Une erreur a empêché la sauvegarde de la carte');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setCard, setStatus, id, openSnackbar, history]
  );

  const deleteCard = useCallback(async () => {
    setErrorMessage('');
    setStatus(FetchingStatus.Fetching);
    try {
      await deleteData(`/visual/${id}`);
      openSnackbar(`La carte a bien été effacée`);
      history.push('/');
    } catch (error) {
      openSnackbar(`Une erreur a empêché d'effacer de la carte`);
    }
    setStatus(FetchingStatus.Fetched);
  }, [setStatus, id, openSnackbar, history]);

  return { card, status, updateCard, deleteCard, errorMessage };
}

export default useCard;
