import { useEffect, useState } from 'react';

import { FetchingStatus, fetchWithToken } from '../utils';

function useGetFetch<T>(path: string): [T | undefined, string] {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Idle);
  const [data, setData] = useState<T>();

  useEffect(() => {
    const fetchData = async () => {
      setStatus(FetchingStatus.Fetching);
      const response = await fetchWithToken(path);
      const newData = await response.json();

      setData(newData);
      setStatus(FetchingStatus.Fetched);
    };

    fetchData();
  }, [path]);

  return [data, status];
}

export default useGetFetch;
