import React, { useCallback, useState } from 'react';
import StandardButton from '../components/ui/Buttons/StandardButton';
import H2 from '../components/ui/H2';
import User from '../components/User/User';
import UserForm from '../components/User/UserForm';
import useUsers from '../hooks/useUsers';
import { UserDTO } from '../models';
import Label from '../components/ui/Label';

const Users: React.FC = () => {
  const { users, actions } = useUsers();
  const [addingUserMode, setAddingUserMode] = useState(false);

  const onNewCategorySave = useCallback(
    (newData: UserDTO) => {
      actions.updateUser(newData);
      setAddingUserMode(false);
    },
    [setAddingUserMode, actions]
  );
  return (
    <div>
      <div className="flex justify-between mb-8 items-center">
        <H2>Utilisateurs</H2>
      </div>

      {users && (
        <div className="grid grid-cols-1  md:grid-cols-2  xl:grid-cols-3  gap-4">
          {users.map((user) => (
            <User user={user} key={user._id} actions={actions} />
          ))}
        </div>
      )}
      {addingUserMode ? (
        <>
          <div className="mb-4">
            <Label> Nouvel Utilisateur : </Label>
          </div>
          <div className="bg-white my-8 p-4 shadow-md max-w-sm">
            <UserForm
              user={{
                email: '',
                name: '',
                access_type: '',
              }}
              onSecondaryClick={() => setAddingUserMode(false)}
              onPrimaryClick={onNewCategorySave}
            />{' '}
          </div>
        </>
      ) : (
        <StandardButton onClick={() => setAddingUserMode(true)}>
          Ajouter un utilisateur
        </StandardButton>
      )}
    </div>
  );
};

export default Users;
