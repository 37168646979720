import React, { useCallback, useState } from 'react';

import Theme from '../components/Theme/Theme';
import ThemeForm from '../components/Theme/ThemeForm';
import StandardButton from '../components/ui/Buttons/StandardButton';

import H2 from '../components/ui/H2';
import Label from '../components/ui/Label';

import useTheme from '../hooks/useThemes';
import { ThemeDTO } from '../models';

const Themes: React.FC = () => {
  const { themes, subThemes, actions } = useTheme();
  const [addingThemeMode, setAddingThemeMode] = useState(false);

  const onNewThemeSave = useCallback(
    (newData: ThemeDTO) => {
      actions.updateTheme(newData);
      setAddingThemeMode(false);
    },
    [setAddingThemeMode, actions]
  );
  return (
    <div>
      <div className="flex justify-between mb-8 items-center">
        <H2>Thèmes</H2>
      </div>

      {themes &&
        subThemes &&
        themes.map((theme) => (
          <Theme
            theme={theme}
            key={theme._id}
            subThemes={subThemes.filter(({ parent }) => parent === theme._id)}
            actions={actions}
          />
        ))}
      {addingThemeMode ? (
        <div className="mb-16">
          <div className="mb-4">
            <Label> Nouveau Theme </Label>
          </div>
          <ThemeForm
            theme={{
              name_FR: '',
              name_EN: '',
              restricted: false,
              members: [],
            }}
            onSecondaryClick={() => setAddingThemeMode(false)}
            onPrimaryClick={onNewThemeSave}
          />
        </div>
      ) : (
        <div className="flex justify-end mt-16">
          <StandardButton onClick={() => setAddingThemeMode(true)}>
            Ajouter un theme
          </StandardButton>
        </div>
      )}
    </div>
  );
};

export default Themes;
