import React from 'react';

interface StandardButtonProps {
  secondary?: boolean;
  onClick?: any;
  disabled?: boolean;
}
const StandardButton: React.FC<StandardButtonProps> = ({
  children,
  secondary,
  onClick,
  disabled,
}) => {
  const mainStyles = secondary
    ? `bg-white border-des-green text-des-green hover:text-white hover:bg-des-green  hover:border-white`
    : `bg-des-green  border-des-green text-white  
   hover:text-des-green hover:bg-white  hover:border-des-green   `;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={` flex justify-center items-center transition duration-200 ease-in-out 
      rounded-md border py-2 px-4 focus:outline-none disabled:opacity-50 disabled:cursor-default ${
        disabled ? '' : mainStyles
      }`}
    >
      <div>{children}</div>
    </button>
  );
};

export default StandardButton;
