import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useGetFetch from '../../hooks/useGetFetch';
import { ThemeModel, SubThemeData, SubThemeModel } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import SubmitButton from '../ui/Buttons/SubmitButton';
import FormElementWrapper from '../ui/FormElementWrapper';
import Input from '../ui/Input';
import Label from '../ui/Label';
import Select from '../ui/Select';

interface SubThemeFormProps {
  subTheme: SubThemeData;
  onPrimaryClick: (newData: any) => void;
  onSecondaryClick: () => void;
}

const SubThemeForm: React.FC<SubThemeFormProps> = ({
  subTheme,
  onSecondaryClick,
  onPrimaryClick,
}) => {
  const { name_FR, name_EN, parent } = subTheme;

  const [themes] = useGetFetch<ThemeModel[]>('/categories/all');
  const { register, handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      name_FR,
      name_EN,
      parent,
    },
  });

  const onSubmit = useCallback(
    (newData: any) => {
      onPrimaryClick(newData);
    },
    [onPrimaryClick]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex ">
          <div className="flex">
            <FormElementWrapper
              error={errors.name_FR}
              errorMessage="Cette information est requise"
            >
              <Input
                name="name_FR"
                placeholder="Nom FR"
                register={register({ required: true })}
              />
            </FormElementWrapper>
            <FormElementWrapper
              error={errors.name_EN}
              errorMessage="Cette information est requise"
            >
              <Input
                name="name_EN"
                placeholder="Nom EN"
                register={register({ required: true })}
              />
            </FormElementWrapper>
            <FormElementWrapper
              error={errors.parent}
              errorMessage="Cette information est requise"
            >
              {themes && (
                <Select
                  name="parent"
                  defaultValue={parent}
                  register={register({ required: true })}
                  selectValues={themes.map((theme) => ({
                    value: theme._id,
                    friendlyName: theme.name_FR,
                  }))}
                />
              )}
            </FormElementWrapper>
          </div>
        </div>
        <div className=" flex mt-8">
          <StandardButton onClick={onSecondaryClick} secondary>
            Annuler
          </StandardButton>
          <div className="ml-8">
            <SubmitButton>Enregistrer</SubmitButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default SubThemeForm;
