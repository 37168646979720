import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

interface LoginProps {
  authError?: boolean;
}
const Login: React.FC<LoginProps> = ({ authError }) => {
  const { token } = useParams<{ token: string }>();

  const { setToken, getToken, isTokenExpired, login, logout } = useAuth();
  const location = useLocation<any>();
  const history = useHistory();

  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    if (token) {
      setToken(token);
      history.replace(from);
    } else if (getToken()) {
      history.replace(from);
    } else if (!authError) {
      login();
    }
  }, [token, setToken, history, from, getToken, login, authError]);
  useEffect(() => {
    if (getToken() && isTokenExpired()) {
      logout();
    }
  });
  return (
    <>
      {authError || (getToken() && isTokenExpired()) ? (
        <div>Accès refusé</div>
      ) : (
        <div> Authentification...</div>
      )}
    </>
  );
};

export default Login;
