import React from 'react';
import placeholderImage from '../../assets/placeholder-1200.png';

interface ImageProps {
  alt: string;
  src: string;
}
const Image: React.FC<ImageProps> = ({ children, alt, src }) => (
  <img
    className="object-contain mb-4"
    alt={alt}
    src={src || placeholderImage}
  />
);

export default Image;
