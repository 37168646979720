import React from 'react';

interface LabelProps {
  disabled?: boolean;
}
const Label: React.FC<LabelProps> = ({ children, disabled }) => (
  <div
    className={`text-lg ${
      disabled ? 'text-gray-400' : 'text-des-text-gray'
    } font-light pr-6 `}
  >
    {children}
  </div>
);

export default Label;
