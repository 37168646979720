import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import desjardinsLogo from '../../assets/logo-desjardins.png';

const Headbar: React.FC = () => {
  const { logout } = useAuth();
  return (
    <div className="w-screen bg-des-green h-32">
      <div className=" h-full mx-auto  flex items-center">
        <div className="w-1/6 h-full">
          <Link to="/">
            <img
              className="object-contain h-full m-auto"
              src={desjardinsLogo}
              alt="desjardins logo"
            />
          </Link>
        </div>
        <div className="w-5/6 pl-4 flex justify-between items-center">
          <Link to="/">
            <div className="text-4xl text-white font-bold max-w-lg hover:underline cursor-pointer">
              Cartothèque Desjardins Administration
            </div>
          </Link>
          <button
            type="button"
            onClick={logout}
            className="text-md pr-12 text-white hover:underline cursor-pointer focus:outline-none"
          >
            Se Déconnecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Headbar;
