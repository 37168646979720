import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Input from '../ui/Input';
import Label from '../ui/Label';

import SubmitButton from '../ui/Buttons/SubmitButton';
import { SubThemeModel, CardModel, CardData, CardDTO } from '../../models';
import Select from '../ui/Select';
import FormElementWrapper from '../ui/FormElementWrapper';

import UploadImageForm from '../SubTheme/UploadImageForm';
import Image from '../ui/Image';
import ConfirmationModal from '../ui/ConfirmationModal';
import StandardButton from '../ui/Buttons/StandardButton';

interface EditCardFormProps {
  card: CardModel | CardData;
  subThemes: SubThemeModel[];
  onSave: (newCard: CardDTO) => void;
  onDelete: () => void;
}

const EditCardForm: React.FC<EditCardFormProps> = ({
  card,
  subThemes,
  onSave,
  onDelete,
}) => {
  const {
    restricted,
    thumbnail,
    members,
    parent,
    name_FR,
    name_EN,
    url,
    lang,
  } = card;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name_FR,
      name_EN,
      restricted,
      parent,
      lang,
      members: members || [],
    },
  });
  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnail);
  const [cardUrl, setCardUrl] = useState(url);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const history = useHistory();

  const onSubmit = useCallback(
    (newData: CardDTO) => {
      const cardDto = {
        ...newData,
        thumbnail: thumbnailUrl,
        url: cardUrl,
      };

      onSave(cardDto);
      history.push('/cards');
    },
    [onSave, thumbnailUrl, cardUrl, history]
  );

  const onDeleteConfirm = useCallback(() => {
    setDeleteConfirmOpen(false);
    onDelete();
  }, [onDelete, setDeleteConfirmOpen]);

  return (
    <div className=" ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col max-w-7xl">
          <div className=" flex w-full">
            <div className="w-6/12 max-w-2xl">
              <Image alt={`${name_FR} carte`} src={cardUrl} />
              <UploadImageForm name="cardUrl" onImageUpload={setCardUrl}>
                Téléverser une autre carte
              </UploadImageForm>
            </div>
            <div className=" pl-8 w-6/12 flex flex-col">
              <FormElementWrapper
                error={errors.name_FR}
                errorMessage="Cette information est requise"
              >
                <Label>Nom de la carte FR</Label>
                <Input name="name_FR" register={register({ required: true })} />
              </FormElementWrapper>

              <FormElementWrapper
                error={errors.name_EN}
                errorMessage="Cette information est requise"
              >
                <Label>Nom de la carte EN</Label>
                <Input name="name_EN" register={register({ required: true })} />
              </FormElementWrapper>

              {/* <FormElementWrapper>
                <Label>Accès limité à certains utilisateurs </Label>
                <Checkbox name="restricted" register={register} />
              </FormElementWrapper>
              <FormElementWrapper>
                <Label disabled={!restrictedValue}>Courriels autorisés</Label>
                <Controller
                  name="members"
                  control={control}
                  render={({ onChange, value }) => (
                    <EmailsInput
                      disabled={!restrictedValue}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FormElementWrapper> */}
              <FormElementWrapper
                error={errors.parent}
                errorMessage="Cette information est requise"
              >
                <Label>Sous-thème associé </Label>
                <Select
                  name="parent"
                  defaultValue={parent}
                  register={register({ required: true })}
                  selectValues={subThemes.map((subTheme) => ({
                    value: subTheme._id,
                    friendlyName: subTheme.name_FR,
                  }))}
                />
              </FormElementWrapper>
              <FormElementWrapper
                error={errors.lang}
                errorMessage="Cette information est requise"
              >
                <Label>Langue du contenu (incl. Note légale) </Label>
                <Select
                  name="lang"
                  register={register({ required: true })}
                  defaultValue={lang}
                  selectValues={[
                    {
                      value: 'FRA',
                      friendlyName: 'Français',
                    },
                    {
                      value: 'ENG',
                      friendlyName: 'Anglais',
                    },
                  ]}
                />
              </FormElementWrapper>
              <FormElementWrapper>
                <div className="flex flex-col items-center ">
                  <Label>Vignette</Label>
                </div>
                <div className="flex flex-col ">
                  <Image alt="vignette" src={thumbnailUrl} />
                  <UploadImageForm
                    name="thumbnail"
                    onImageUpload={setThumbnailUrl}
                  >
                    Téléverser une autre vignette
                  </UploadImageForm>
                </div>
              </FormElementWrapper>
            </div>
          </div>

          <div className="flex justify-between py-4 pr-4 ">
            <StandardButton
              secondary
              disabled={!(card as CardModel)._id}
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Supprimer la carte
            </StandardButton>

            <SubmitButton> Enregistrer</SubmitButton>
          </div>
          <ConfirmationModal
            onConfirm={onDeleteConfirm}
            onCancel={() => setDeleteConfirmOpen(false)}
            isOpen={deleteConfirmOpen}
            confirmText="Supprimer la carte"
          >
            <div> Voulez-vous vraiment supprimer cette carte ? </div>
          </ConfirmationModal>
        </div>
      </form>
    </div>
  );
};

export default EditCardForm;
