import environment from './environment';

export enum FetchingStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Fetched = 'fetched',
  Error = 'error',
}
const apiUrl = environment.REACT_APP_API_URL;

export function fetchWithToken(path = ''): Promise<Response> {
  const token = localStorage.getItem('DESC_TOKEN');

  return fetch(`${apiUrl}${path}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function withFormData(
  path = '',
  formData: FormData,
  method?: string
): Promise<Response> {
  const token = localStorage.getItem('DESC_TOKEN');

  return fetch(`${apiUrl}${path}`, {
    method: method || 'POST',
    body: formData,
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function postJsonData<T>(path = '', data = {}): Promise<T> {
  const token = localStorage.getItem('DESC_TOKEN');
  // Default options are marked with *
  const response = await fetch(`${apiUrl}${path}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    throw Error();
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function putData<T>(path = '', data = {}): Promise<T> {
  const token = localStorage.getItem('DESC_TOKEN');
  // Default options are marked with *
  const response = await fetch(`${apiUrl}${path}`, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    throw Error();
  }
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function deleteData(path = ''): Promise<Response> {
  const token = localStorage.getItem('DESC_TOKEN');
  // Default options are marked with *
  const response = await fetch(`${apiUrl}${path}`, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response; // parses JSON response into native JavaScript objects
}
