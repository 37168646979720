import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { UserModel, UserDTO } from '../models';
import {
  deleteData,
  FetchingStatus,
  fetchWithToken,
  postJsonData,
  putData,
} from '../utils';

export interface UseUsersActions {
  updateUser: (newData: UserDTO, id?: string) => void;
  deleteUser: (id: string) => void;
}
interface UseUsers {
  users: UserModel[];
  actions: UseUsersActions;
}
function useUsers(): UseUsers {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Fetching);
  const [openSnackbar] = useSnackbar();

  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const userResponse = await fetchWithToken(`/users/`);
        const fetchedUsers: UserModel[] = await userResponse.json();
        setUsers(fetchedUsers);

        setStatus(FetchingStatus.Fetched);
      } catch (error) {
        openSnackbar('Une erreur a empêché la récupération des utilisateurs');
      }
      setStatus(FetchingStatus.Fetched);
    };
    getData();
  }, [setUsers]);

  const updateUser = useCallback(
    async (newData: UserDTO, id?: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        let updatedUser: UserModel;
        if (!id) {
          updatedUser = await postJsonData<UserModel>(`/users/`, newData);
        } else {
          updatedUser = await putData<UserModel>(`/users/${id}`, newData);
        }

        openSnackbar(`L'utilisateur a bien été sauvegardé`);
        setUsers((previous) => {
          if (!id) {
            return previous.concat(updatedUser);
          }
          return previous.map((currentUser) => {
            if (currentUser._id === id) {
              return updatedUser;
            }
            return currentUser;
          });
        });
      } catch (error) {
        openSnackbar(`Une erreur a empêché la sauvegarde de l'utilisateur`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const deleteUser = useCallback(
    async (id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        await deleteData(`/users/${id}`);
        openSnackbar(`L'utilisateur a bien été effacé`);
        setUsers((previous) => previous.filter(({ _id }) => _id !== id));
      } catch (error) {
        openSnackbar(`Une erreur a empêché d'effacer l'utilisateur`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar, setUsers]
  );

  return {
    users,
    actions: {
      updateUser,
      deleteUser,
    },
  };
}

export default useUsers;
