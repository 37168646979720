import React from 'react';
import { Link } from 'react-router-dom';

interface SideMenuElementListProps {
  to: string;
}
const SideMenuElementList: React.FC<SideMenuElementListProps> = ({
  children,
  to,
}) => (
  <li className="text-des-green my-2 text-md uppercase hover:underline">
    <Link to={to}>{children}</Link>
  </li>
);

export default SideMenuElementList;
