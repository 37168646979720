import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import './App.css';
import About from './pages/About';
import Cards from './pages/Cards';
import Login from './pages/Login';
import PrivateRoute from './components/utils/PrivateRoute';
import Layout from './components/layout/Layout';
import EditCard from './pages/EditCard';
import Themes from './pages/Themes';
import Files from './pages/Files';
import Users from './pages/Users';
import Help from './pages/Help';

function App(): React.ReactElement {
  return (
    <Router>
      <LastLocationProvider>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/login/error">
            <Login authError />
          </Route>
          <Route exact path="/login/:token">
            <Login />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
          <PrivateRoute path="/">
            <PrivateRoute path="/" exact>
              <Redirect to="/cards" />
            </PrivateRoute>
            <Layout>
              <PrivateRoute path="/cards" exact>
                <Cards />
              </PrivateRoute>
              <PrivateRoute path="/themes" exact>
                <Themes />
              </PrivateRoute>

              <PrivateRoute exact path="/edit-cards">
                <EditCard />
              </PrivateRoute>

              <PrivateRoute exact path="/edit-cards/:id">
                <EditCard />
              </PrivateRoute>
              <PrivateRoute exact path="/files">
                <Files />
              </PrivateRoute>
              <PrivateRoute exact path="/users">
                <Users />
              </PrivateRoute>
              <PrivateRoute exact path="/help">
                <Help />
              </PrivateRoute>
            </Layout>
          </PrivateRoute>
        </Switch>
      </LastLocationProvider>
    </Router>
  );
}

export default App;
