import React from 'react';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

interface PreviousProps {
  to?: string;
}
const Previous: React.FC<PreviousProps> = ({ to }) => {
  const lastLocation = useLastLocation();
  return (
    <div className="text-des-green pb-4 hover:underline">
      <Link to={to || lastLocation?.pathname || '/'}>← Précédent</Link>
    </div>
  );
};

export default Previous;
