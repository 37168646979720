import React from 'react';

interface H3Props {
  className?: string;
}
const H4: React.FC<H3Props> = ({ children, className }) => (
  <h4 className={`${className} font-sans text-lg text-des-green mb-4 `}>
    {children}
  </h4>
);

export default H4;
