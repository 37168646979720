import React from 'react';
import { Link } from 'react-router-dom';
import H2 from '../components/ui/H2';
import H3 from '../components/ui/H3';

const Help: React.FC = () => (
  <div className="max-w-2xl">
    <H2>Questions Pratiques</H2>
    <div id="cartes" className="mt-4">
      <H3>Cartes</H3>
    </div>
    <P>
      Depuis la page <PLink to="/cards">Cartes</PLink>, vous pouvez ajouter,
      modifier ou supprimer les cartes disponibles sur la plateforme d’envoi.
    </P>
    <P>
      Sur cette page, les cartes sont organisées par thèmes et sous-thèmes. Si
      vous cliquez sur une carte, vous accéderez à la page de modification de
      cette carte, qui vous permettra de changer l’ensemble de ses informations
      (image, vignette, nom de la carte (FR/EN), sous-thème associé, langue).
    </P>
    <P>
      Le bouton “Ajouter une carte” vous permet de créer une nouvelle carte.
      Chaque carte doit avoir un sous-thème associé (voir la page Thèmes pour la
      création de sous-thèmes), ainsi qu’une image, un nom (FR/EN), une vignette
      et une langue.
    </P>
    <div id="themes" className="mt-4">
      <H3>Thèmes</H3>
    </div>
    <P>
      La page <PLink to="/themes">Thèmes</PLink> regroupe les catégories et
      sous-catégories (“Sous-Thèmes”) dans lesquelles sont regroupées les
      cartes. Pour apparaître dans la plateforme d’envoi, une carte doit être
      associée à un Sous-Thème. Lors de la création d’un Thème, pensez-donc à
      créer au moins un Sous-Thème associé, afin de pouvoir y intégrer des
      cartes.
    </P>
    <div className="mb-4">
      <P>
        Voici les différentes actions que vous pouvez effectuer depuis la page
        Thèmes:
      </P>
    </div>
    <ul className="ml-4">
      <LI title="Ajout de thèmes: ">
        le bouton “Ajouter un thème” en bas à droite de votre page vous permet
        de simplement ajouter un thème, en indiquant son nom (en FR et en EN).
      </LI>
      <LI title="Édition/Suppression des thèmes existants: ">
        vous pouvez modifier à tout moment le nom d’un thème (EN / FR), en
        cliquant sur le bouton “Editer” à côté du nom du Thème. Vous pouvez
        également supprimer un thème, à condition d’avoir d’abord supprimé
        l’ensemble des sous-thèmes associés.
      </LI>
      <LI title="Ajout de sous-thèmes: ">
        sous chaque thème, vous verrez un bouton “Ajouter un sous-thème”, qui
        vous permettra d’indiquer le nom (FR / EN) et le thème associé.
      </LI>
      <LI title="Édition/Suppression de sous-thèmes: ">
        En utilisant les boutons à droite du sous-thème que vous souhaitez
        modifier, vous pourrez changer le nom du sous-thème, changer le thème
        associé, ou supprimer le sous-thème.
      </LI>
    </ul>
    <div id="files" className="mt-4">
      <H3>Fichiers</H3>
    </div>
    <div className="mb-4">
      <P>
        La page <PLink to="/themes">Fichiers</PLink> vous permet de gérer les
        documents disponibles pour l’ensemble des utilisateurs de la plateforme
        d’envoi. Elle comprend:
      </P>
    </div>
    <ul className="ml-4">
      <LI title="F.A.Q. et Bonnes Pratiques: ">
        vous pouvez téléverser un nouveau fichier pour remplacer le fichier de
        F.A.Q. ou de Bonnes Pratiques. Le bouton “Voir le fichier” vous permet
        également de consulter le document qui est disponible sur la plateforme
        d’envoi.
      </LI>
      <LI title="Signatures: ">
        vous pouvez téléverser une nouvelle image de signature, supprimer ou
        remplacer des signatures existantes, qui seront disponibles pour
        l’ensemble des utilisateurs de la plateforme d’envoi.
      </LI>
    </ul>
    <div id="files" className="mt-4">
      <H3>Utilisateurs</H3>
    </div>
    <P>
      L’ensemble des administrateurs peuvent ajouter, éditer ou supprimer les
      profils d’utilisateurs.
    </P>
    <P>
      L’ajout d’utilisateur sur la plateforme d’administration ne comprend pas
      de phase de validation: ainsi, vous pouvez ajouter n’importe quelle
      adresse courriel valide dans les utilisateurs de la plateforme
      d’administration.
    </P>
    <P>
      Cette étape de validation est faite par le SSO Desjardins, qui est une
      étape nécessaire pour se connecter à la plateforme d’administration.
      Ainsi, pour qu’un utilisateur puisse accéder à la plateforme
      d’administration, il doit pouvoir se connecter via le SSO Desjardins,
      <b> et </b>
      avoir été ajouté dans la page Utilisateurs de la plateforme
      d’administration.
    </P>
  </div>
);

const LI: React.FC<{ title: string }> = ({ children, title }) => (
  <li className="mb-2 ">
    <div className="h-2 w-2 bg-des-green float-left mt-2 mr-2" />
    <div className="ml-4">
      <span className="font-bold">{title}</span>
      {children}
    </div>
  </li>
);

const PLink: React.FC<{ to: string }> = ({ children, to }) => (
  <Link to={to} className="text-des-green hover:underline">
    {children}
  </Link>
);

const P: React.FC = ({ children }) => <p className="mt-4">{children}</p>;
export default Help;
