import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const PrivateRoute: React.FC<RouteProps> = ({
  children,
  path,
  exact,
}): React.ReactElement => {
  const { isTokenExpired } = useAuth();
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        !isTokenExpired() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
