import React, { useCallback, useMemo, useState } from 'react';
import { UseThemesActions } from '../../hooks/useThemes';
import { ThemeModel, SubThemeModel } from '../../models';
import SubTheme from '../SubTheme/SubTheme';
import SubThemeForm from '../SubTheme/SubThemeForm';
import StandardButton from '../ui/Buttons/StandardButton';
import ConfirmationModal from '../ui/ConfirmationModal';
import H3 from '../ui/H3';
import Label from '../ui/Label';
import ThemeForm from './ThemeForm';

interface SubThemeProps {
  theme: ThemeModel;
  subThemes: SubThemeModel[];
  actions: UseThemesActions;
}
export const Theme: React.FC<SubThemeProps> = ({
  theme,
  subThemes,
  actions,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [addingSubThemeMode, setAddingSubThemeMode] = useState(false);

  const onAddingSubTheme = useCallback(
    (newData) => {
      actions.updateSubTheme(newData);
      setAddingSubThemeMode(false);
    },
    [actions]
  );

  const onSave = useCallback(
    (newData) => {
      setEditMode(false);
      actions.updateTheme(newData, theme._id);
    },
    [actions, setEditMode, theme._id]
  );
  const onDelete = useCallback(() => {
    actions.deleteTheme(theme._id);
  }, [actions, theme]);
  const canBeDeleted = useMemo(() => !subThemes.length, [subThemes]);
  return (
    <div>
      {editMode ? (
        <ThemeForm
          theme={theme}
          onPrimaryClick={onSave}
          onSecondaryClick={() => setEditMode(false)}
        />
      ) : (
        <>
          <ThemeView
            theme={theme}
            onPrimaryClick={() => setEditMode(true)}
            onSecondaryClick={() => setDeleteConfirmOpen(true)}
            canDelete={canBeDeleted}
          />
          <ConfirmationModal
            onConfirm={onDelete}
            onCancel={() => setDeleteConfirmOpen(false)}
            isOpen={deleteConfirmOpen}
            confirmText="Supprimer le thème"
          >
            <div> Voulez-vous vraiment supprimer ce thème ? </div>
          </ConfirmationModal>
        </>
      )}
      <div className="flex flex-col">
        <Label>{`Sous-thème${subThemes.length > 1 ? 's' : ''} : `}</Label>
        {subThemes.map((subTheme) => (
          <SubTheme
            key={subTheme._id}
            subTheme={subTheme}
            updateSubTheme={actions.updateSubTheme}
            deleteSubTheme={actions.deleteSubTheme}
          />
        ))}
        {addingSubThemeMode ? (
          <div className=" my-8 p-4 ">
            <Label>Nouveau sous-thème : </Label>
            <SubThemeForm
              subTheme={{ name_FR: '', name_EN: '', parent: theme._id }}
              onSecondaryClick={() => setAddingSubThemeMode(false)}
              onPrimaryClick={onAddingSubTheme}
            />
          </div>
        ) : (
          <div className="flex justify-start items-center ml-4 mt-4 mb-16 ">
            <StandardButton onClick={() => setAddingSubThemeMode(true)}>
              Ajouter un sous-theme
            </StandardButton>
          </div>
        )}
      </div>
    </div>
  );
};

interface ThemeViewProps {
  theme: ThemeModel;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  canDelete: boolean;
}

const ThemeView: React.FC<ThemeViewProps> = ({
  theme,
  onSecondaryClick,
  onPrimaryClick,
  canDelete,
}) => (
  <>
    <div className="flex items-center">
      <H3 className="">
        {theme.name_FR} / {theme.name_EN}
      </H3>
      <div className="ml-4 mb-4">
        <StandardButton onClick={onPrimaryClick}>Éditer</StandardButton>
      </div>
      <div className="ml-4 mb-4">
        <StandardButton
          disabled={!canDelete}
          secondary
          onClick={onSecondaryClick}
        >
          Supprimer
        </StandardButton>
      </div>
    </div>
  </>
);

export default Theme;
