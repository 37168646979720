import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { UserData, UserDTO } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import SubmitButton from '../ui/Buttons/SubmitButton';
import ErrorMessage from '../ui/ErrorMessage';
import FormElementWrapper from '../ui/FormElementWrapper';
import Input from '../ui/Input';
import Label from '../ui/Label';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface CategoryFormProps {
  user: UserData;
  onPrimaryClick: (newData: any) => void;
  onSecondaryClick: () => void;
}

const UserForm: React.FC<CategoryFormProps> = ({
  user,
  onSecondaryClick,
  onPrimaryClick,
}) => {
  const { name, email } = user;

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      name,
      email,
    },
  });

  const onSubmit = useCallback(
    (newData: UserDTO) => {
      onPrimaryClick(newData);
    },
    [onPrimaryClick]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col ">
          <div>
            <FormElementWrapper>
              <Label> Nom : </Label>
              <Input
                name="name"
                placeholder="Nom de l'utilisateur"
                register={register({ required: true })}
              />
            </FormElementWrapper>
          </div>
          <div className="my-4">
            <FormElementWrapper>
              <Label> Email : </Label>
              <Input
                name="email"
                placeholder="Email de l'utilisateur"
                register={register({ required: true, pattern: EMAIL_REGEX })}
              />
            </FormElementWrapper>
            <div className="ml-4">
              {errors.email && (
                <ErrorMessage>L&apos;email n&apos;est pas valide</ErrorMessage>
              )}
            </div>
          </div>
          <div className="flex justify-between my-4">
            <div>
              <StandardButton onClick={onSecondaryClick} secondary>
                Annuler
              </StandardButton>
            </div>
            <div className="mx-4">
              <SubmitButton>Enregistrer</SubmitButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserForm;
