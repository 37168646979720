import React, { useCallback, useState } from 'react';
import { UseThemesActions } from '../../hooks/useThemes';
import { SubThemeModel } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import ConfirmationModal from '../ui/ConfirmationModal';

import Label from '../ui/Label';

import SubThemeForm from './SubThemeForm';

interface SubThemeProps {
  subTheme: SubThemeModel;
  updateSubTheme: UseThemesActions['updateSubTheme'];
  deleteSubTheme: UseThemesActions['deleteSubTheme'];
}
export const SubTheme: React.FC<SubThemeProps> = ({
  subTheme,
  updateSubTheme,
  deleteSubTheme,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const toggleEditMode = useCallback(() => setEditMode((value) => !value), [
    setEditMode,
  ]);

  const onSave = useCallback(
    (newData) => {
      setEditMode(false);
      updateSubTheme(newData, subTheme._id);
    },
    [updateSubTheme, setEditMode, subTheme._id]
  );
  const onDelete = useCallback(() => deleteSubTheme(subTheme._id), [
    deleteSubTheme,
    subTheme._id,
  ]);
  return (
    <div className="p-4 hover:bg-gray-200">
      {editMode ? (
        <SubThemeForm
          subTheme={subTheme}
          onSecondaryClick={toggleEditMode}
          onPrimaryClick={onSave}
        />
      ) : (
        <>
          <SubThemeView
            subTheme={subTheme}
            onSecondaryClick={() => setDeleteConfirmOpen(true)}
            onPrimaryClick={toggleEditMode}
          />
          <ConfirmationModal
            onConfirm={onDelete}
            onCancel={() => setDeleteConfirmOpen(false)}
            isOpen={deleteConfirmOpen}
            confirmText="Supprimer le sous-thème"
          >
            <div>
              Voulez-vous vraiment supprimer ce sous-thème ?<br />
              <b>
                Veuillez noter que toutes les cartes associées à ce sous-thème
                seront supprimées définitivement.
              </b>
            </div>
          </ConfirmationModal>
        </>
      )}
    </div>
  );
};

interface SubThemeViewProps {
  subTheme: SubThemeModel;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
}

const SubThemeView: React.FC<SubThemeViewProps> = ({
  subTheme,
  onSecondaryClick,
  onPrimaryClick,
}) => (
  <>
    <div className="flex justify-between items-center ">
      <div>
        {subTheme.name_FR} / {subTheme.name_EN}
      </div>

      <div className=" flex ">
        <div className="mr-8">
          <StandardButton onClick={onSecondaryClick} secondary>
            Supprimer
          </StandardButton>
        </div>
        <StandardButton onClick={onPrimaryClick}>Éditer</StandardButton>
      </div>
    </div>
  </>
);

export default SubTheme;
