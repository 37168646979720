import React from 'react';

const SubmitButton: React.FC = ({ children }) => (
  <button
    type="submit"
    className="bg-des-green px-4 py-2 rounded-md border border-des-green text-white flex justify-center items-center 
    hover:text-des-green hover:bg-white  hover:border-des-green transition duration-200 ease-in-out focus:outline-none "
  >
    <div>{children}</div>
  </button>
);

export default SubmitButton;
