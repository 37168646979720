import React from 'react';
import { Link } from 'react-router-dom';
import { CardModel } from '../../models';

interface CardProps {
  card: CardModel;
}
const Card: React.FC<CardProps> = ({ card }) => {
  const { name_FR, name_EN, thumbnail, _id } = card;
  return (
    <div className="bg-white transition duration-500 ease-in-out hover:shadow-md">
      <Link to={`/edit-cards/${_id}`}>
        <img alt={name_FR} src={thumbnail} />
        <h3 className="p-4">
          {name_FR} / {name_EN}
        </h3>
      </Link>
    </div>
  );
};

export default Card;
