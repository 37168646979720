import { useMemo } from 'react';
import { CardModel, SubThemeModel, ThemeModel } from '../models';
import useGetFetch from './useGetFetch';
import useTheme from './useThemes';

interface SubThemeWithCards extends SubThemeModel {
  cards: CardModel[];
}

interface ThemeWithSubThemeAndVisuals extends ThemeModel {
  subThemes: SubThemeWithCards[];
}

const useCardsByTheme = () => {
  const [cards] = useGetFetch<CardModel[]>('/visual');
  const { themes, subThemes } = useTheme();
  const cardsByTheme: ThemeWithSubThemeAndVisuals[] = useMemo(() => {
    const subThemesWithVisual = subThemes
      .map((subTheme) => ({
        ...subTheme,
        cards: cards
          ? cards.filter(({ parent }) => parent === subTheme._id)
          : [],
      }))
      .filter((subTheme) => !!subTheme.cards.length);
    return themes
      .map((theme) => ({
        ...theme,
        subThemes: subThemesWithVisual.filter(
          ({ parent }) => parent === theme._id
        ),
      }))
      .filter((theme) => !!theme.subThemes.length);
  }, [cards, themes, subThemes]);

  return cardsByTheme;
};

export default useCardsByTheme;
