import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import UploadButton from '../ui/Buttons/UploadButton';

import { withFormData } from '../../utils';

interface UploadImageFormProps {
  name: string;
  onImageUpload: (newImageUrl: string) => void;
}

const UploadImageForm: React.FC<UploadImageFormProps> = ({
  children,
  name,
  onImageUpload,
}) => {
  const [loading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const onNewImage = useCallback(
    async ({ target }) => {
      setLoading(true);
      const { files } = target;
      const formData = new FormData();
      formData.append(name, files[0]);

      try {
        const res = await withFormData(`/upload-image/${name}`, formData);

        const newImageUrl = await res.text();
        onImageUpload(newImageUrl);
      } catch (error) {
        console.error(error);
        openSnackbar(
          `Une erreur s'est produite : Impossible de téléverser l'image`
        );
      }

      setLoading(false);
    },
    [onImageUpload, setLoading, name, openSnackbar]
  );

  return (
    <UploadButton onChange={onNewImage} domId={name} loading={loading}>
      {children}
    </UploadButton>
  );
};

export default UploadImageForm;
