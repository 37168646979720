import React from 'react';
import { FieldError } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

interface FormElementWrapperProps {
  error?: FieldError;
  errorMessage?: string;
}

const FormElementWrapper: React.FC<FormElementWrapperProps> = ({
  children,
  error,
  errorMessage,
}) => (
  <div className="p-4">
    <div className="flex w-full  justify-left items-center">{children}</div>
    {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </div>
);

export default FormElementWrapper;
