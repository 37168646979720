import React from 'react';

interface UploadButtonProps {
  domId: string;
  loading: boolean;
  accept?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}
const UploadButton: React.FC<UploadButtonProps> = ({
  children,
  domId,
  loading,
  onChange,
  accept,
}) => (
  <div
    className="bg-white border w-full  py-2 border-gray-200 rounded-lg text-des-green text-center
  cursor-pointer hover:shadow-md transition duration-200 ease-in-out"
  >
    <label className="cursor-pointer" htmlFor={domId}>
      {loading ? 'Loading...' : children}
    </label>
    <input
      style={{
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      }}
      type="file"
      accept={accept || 'image/png, image/jpeg'}
      id={domId}
      onChange={onChange}
    />
  </div>
);

export default UploadButton;
