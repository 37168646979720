import React from 'react';
import { Link } from 'react-router-dom';
import StandardButton from '../components/ui/Buttons/StandardButton';
import Card from '../components/Cards/Card';
import H2 from '../components/ui/H2';

import H3 from '../components/ui/H3';
import useCardsByTheme from '../hooks/useCardsByTheme';
import H4 from '../components/ui/H4';

const Cards: React.FC = () => {
  const cardsByTheme = useCardsByTheme();

  return (
    <div>
      <div className="flex justify-between mb-8 items-center">
        <H2>Cartes</H2>
        <Link to="/edit-cards">
          <StandardButton>Ajouter une carte </StandardButton>
        </Link>
      </div>

      {cardsByTheme &&
        cardsByTheme.map((theme) => (
          <div className="flex flex-col">
            <H3>
              {theme?.name_FR} / {theme?.name_EN}
            </H3>
            {theme.subThemes.map((subTheme) => (
              <div className="m-4">
                <H4>
                  {subTheme?.name_FR} / {subTheme?.name_EN}
                </H4>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
                  {subTheme.cards.map((card) => (
                    <Card key={card._id} card={card} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default Cards;
