import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { UseFilesActions } from '../../hooks/useFiles';
import { FileModel, FileTypes } from '../../models';
import StandardButton from '../ui/Buttons/StandardButton';
import UploadButton from '../ui/Buttons/UploadButton';

interface UploadPdfFileProps {
  file: Partial<FileModel>;
  actions: UseFilesActions;
  type: FileTypes;
}

const UploadPdfFile: React.FC<UploadPdfFileProps> = ({
  file,
  type,
  actions,
}) => {
  const onUpload = useCallback(
    async ({ target }) => {
      const { files } = target;
      const formData = new FormData();
      formData.append('uploadedFile', files[0]);
      formData.append('type', type);

      actions.updateFile(formData, file._id);
    },
    [file, actions, type]
  );
  const labelText = useMemo(() => getLabelTitle(type), [type]);
  return (
    <div className="bg-white my-8 p-4 shadow-md flex flex-col items-center h-56 justify-around">
      <div> {labelText}</div>
      {file.url && (
        <Link to={{ pathname: file.url }} target="_blank">
          <StandardButton> Voir le fichier </StandardButton>
        </Link>
      )}

      <UploadButton
        onChange={onUpload}
        domId={type}
        loading={false}
        accept="application/pdf"
      >
        Téléverser un nouveau fichier
      </UploadButton>
    </div>
  );
};

const getLabelTitle = (type: FileTypes): string => {
  switch (type) {
    case FileTypes.Faq: {
      return 'F.A.Q';
    }
    case FileTypes.GoodPractices: {
      return 'Bonnes pratiques';
    }
    default: {
      return '';
    }
  }
};

export default UploadPdfFile;
