import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { environment } from '../environment';

const apiUrl = environment.REACT_APP_API_URL;

const useAuth = (): any => {
  const history = useHistory();
  const getToken = (): string | null => localStorage.getItem('DESC_TOKEN');
  const setToken = (token: string): void => {
    localStorage.setItem('DESC_TOKEN', token);
  };

  const isTokenExpired = () => {
    try {
      const token = getToken();

      if (!token) {
        return true;
      }
      const { exp } = jwtDecode<JwtPayload>(token);

      if (!exp) {
        return true;
      }

      return Date.now() >= exp * 1000;
    } catch (e) {
      return true;
    }
  };

  const login = () => {
    window.location.href = `${apiUrl}/oidc/auth?origin=bo`;
  };

  const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('DESC_TOKEN');
    history.push('login');
  };

  return { getToken, setToken, isTokenExpired, login, logout };
};

export default useAuth;
