import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';

import { FileModel } from '../models';
import {
  deleteData,
  FetchingStatus,
  fetchWithToken,
  withFormData,
} from '../utils';

export interface UseFilesActions {
  updateFile: (formData: FormData, id?: string) => void;
  deleteFile: (id: string) => void;
}
interface UseFiles {
  files: FileModel[];
  actions: UseFilesActions;
}
function useFiles(): UseFiles {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Fetching);
  const [openSnackbar] = useSnackbar();

  const [files, setFiles] = useState<FileModel[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const filesResponse = await fetchWithToken(`/files/`);
        const fetchedFiles: FileModel[] = await filesResponse.json();
        setFiles(fetchedFiles);

        setStatus(FetchingStatus.Fetched);
      } catch (error) {
        openSnackbar('Une erreur a empêché la récupération des fichiers');
      }
      setStatus(FetchingStatus.Fetched);
    };
    getData();
  }, [setFiles]);

  const updateFile = useCallback(
    async (formData: FormData, id?: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        let response: Response;

        if (!id) {
          response = await withFormData(`/files/`, formData);
        } else {
          response = await withFormData(`/files/${id}`, formData, 'PUT');
        }
        if (!response.ok) {
          throw Error();
        }

        const updatedFile: FileModel = await response.json();
        openSnackbar(`La fichier a bien été sauvegardé`);
        setFiles((previous) => {
          if (!id) {
            return previous.concat(updatedFile);
          }
          return previous.map((currentCategory) => {
            if (currentCategory._id === id) {
              return updatedFile;
            }
            return currentCategory;
          });
        });
      } catch (error) {
        openSnackbar('Une erreur a empêché la sauvegarde du fichier');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const deleteFile = useCallback(
    async (id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        await deleteData(`/files/${id}`);
        openSnackbar(`Le fichier a bien été effacé`);
        setFiles((previous) => previous.filter(({ _id }) => _id !== id));
      } catch (error) {
        openSnackbar(`Une erreur a empêché d'effacer le fichier`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar, setFiles]
  );

  return {
    files,
    actions: {
      updateFile,
      deleteFile,
    },
  };
}

export default useFiles;
