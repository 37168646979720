import React from 'react';
import SideMenuElementList from './SideMenuElementList';

const SideMenu = () => (
  <div className="m-4 mt-8">
    <div className="border-des-green text-des-green border-b text-3xl mb-8">
      Menu
    </div>
    <ul className="ml-6">
      <SideMenuElementList to="/cards">Cartes</SideMenuElementList>
      <SideMenuElementList to="/themes">Thèmes</SideMenuElementList>
      <SideMenuElementList to="/files">Fichiers</SideMenuElementList>
      <SideMenuElementList to="/users">Utilisateurs</SideMenuElementList>
      <SideMenuElementList to="/help">Aide</SideMenuElementList>
    </ul>
  </div>
);

export default SideMenu;
